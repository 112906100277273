var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.board ? _c('div', {
    staticClass: "board-container"
  }, [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', {
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v(_vm._s(_vm.board.subject))])]), _c('tr', [_c('td', {
    attrs: {
      "colspan": "2"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("Writer : " + _vm._s(_vm.board.writer.name))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("Date : " + _vm._s(_vm.board.createdAt.toDate()))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("Views : " + _vm._s(_vm.board.viewCount))])])], 1)], 1)]), _c('tr', [_c('td', {
    staticClass: "content_box",
    attrs: {
      "colspan": "2"
    }
  }, [_c('div', {
    staticStyle: {
      "white-space": "pre"
    }
  }, [_vm._v(_vm._s(_vm.board.content))])])])]), _c('div', {
    staticClass: "board-bottom"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "justify": "end"
    }
  }, [_c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isUserWriter,
      expression: "isUserWriter"
    }],
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "small": "",
      "exact": "",
      "color": "grey-6"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_vm._v("Delete")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "small": "",
      "exact": "",
      "color": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push(`${_vm.$route.path}/modify`);
      }
    }
  }, [_vm._v("Modify")])], 1)], 1)], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100px",
    attrs: {
      "small": "",
      "outlined": "",
      "exact": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("List")])], 1)], 1)], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }